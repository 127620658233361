import React from "react";
import Link from 'gatsby-link'

import $ from "jquery"

export class FullOpening extends React.Component {
// export default props => {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      viewPopup: false,
    }
    // this.openModal = this.openModal.bind(this)
  }

  componentDidMount(){
    let visited = localStorage["alreadyVisited"];
    if(visited) {
        this.setState({ viewPopup: false })
        //do not view Popup
        setTimeout(() => {
          this.setState({ viewPopup: false })
          
        }, 2000);
    } else {
        //this is the first time
        localStorage["alreadyVisited"] = true;
        this.setState({ viewPopup: true});
    }
  }

  // const open = function(){
  //     console.log('toto');
  // }
  
  render() {
    return (
      // <div className="full-opening ">
      <div className={`full-opening ${this.state.viewPopup ? "open" : "open"}`}>
        <div className="full-opening--inner">
            <div className="logo">
                <img src="https://associationjaide.fr/wp-content/themes/jaide/img/Logo.png" alt="Jaide" />
            </div>  
        </div>
      </div>
    )
  }
};

export default FullOpening;
