import React from "react";

export default props => (
  <div className="fiveSplitPart">

    {/* Hi I'm a Bar component with the title: */}
    {/* <h2>{props.title}</h2> */}

    <div className="fiveSplitPart--inner">
    
    <div className="fiveSplitPart--item">
        <div className="item-icon">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/maraude.png" alt="JAIDE"/>
        </div>
        <div className="text">
        <h3>Maraudes</h3>
        <p>Tous les week-ends: distribution de repas, moment de convivialité</p>
        </div>
    </div>
    
    <div className="fiveSplitPart--item">
        <div className="item-icon">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/ateliers.png" alt="JAIDE"/>
        </div>
        <div className="text">
        <h3>Ateliers et évènements</h3>
        <p>Jeux de sociétés, estime de soi, cuisine, fête de fin d'année</p>
        </div>
    </div>
    
    <div className="fiveSplitPart--item">
        <div className="item-icon">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/permanences.png" alt="JAIDE"/>
        </div>
        <div className="text">
        <h3>Permanences sociales et juridiques</h3>
        <p>Information et/ou orientation vers un organisme adapté</p>
        </div>
    </div>
    
    <div className="fiveSplitPart--item">
        <div className="item-icon">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/recoltes.png" alt="JAIDE"/>
        </div>
        <div className="text">
        <h3>Récoltes et redistribution</h3>
        <p>Vêtements, fournitures scolaires et caddie solidaire tous les mois</p>
        </div>
    </div>
    
    <div className="fiveSplitPart--item">
        <div className="item-icon">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/reussite-educative.png" alt="JAIDE"/>
        </div>
        <div className="text">
        <h3>Réussite éducative</h3>
        <p>Activités pédagogiques et ludiques auprès de familles en difficulté</p>
        </div>
    </div>

  </div>
  </div>
);
