import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Pagination from '../components/Pagination'
import FullOpening from '../components/FullOpening'
import HeaderBranding from '../components/HeaderBranding'
import SubHeader from '../components/SubHeader'
import FiveSplitPart from '../components/fiveSplitpart'
import ProductItem from '../components/ProductItem'
import LeftContentSlider from '../components/LeftContentSlider'
import TopContentSplit from '../components/TopContentSplit'
import FooterBranding from '../components/footer/FooterBranding'
import {Helmet} from "react-helmet";


export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost

    return (
      <Layout>
        <Helmet>
          <script src="/components/functions/additionalJS.js" type="text/javascript" />
        </Helmet>
        <FullOpening />
        <HeaderBranding />
        <SubHeader title='test' />
        <FiveSplitPart title='test' />
        <ProductItem title='test' />
        {/* <PostList posts={posts} title="Latest posts" />
        <Pagination pageContext={pageContext} pathPrefix="/" /> */}
        {/* <LeftContentSlider /> */}
        {/* <TopContentSplit /> */}
        <FooterBranding />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($ limit: Int!, $ skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
